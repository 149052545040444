#alert-with-entity .alert-picking-elements {
    position: fixed;
    z-index: 3;
    width: 600px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #1BC000;
}


#alert-with-entity .alert-picking-elements .select-title {
    margin-top: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #00A14C;
    display: flex;
    justify-content: center;
}

#alert-with-entity .alert-picking-elements .box-element {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    gap: 10px;
    margin-top: 40px;
}

#alert-with-entity .alert-picking-elements .box-element {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    gap: 10px;
    margin-top: 40px;
}

#alert-with-entity .alert-picking-elements .box-element .selected {
    box-shadow: #00A14C 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 10px;
}

#alert-with-entity .alert-picking-elements .box-element div {
    width: 110px;
    height: 128px;
}

#alert-with-entity .alert-picking-elements .box-element img,
#alert-with-entity .alert-picking-elements .box-element audio,
#alert-with-entity .alert-picking-elements .box-element video,
#alert-with-entity .alert-picking-elements .box-element .link-entity-parser {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #1BC000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

#alert-with-entity .alert-picking-elements .box-element h1 {
    width: 110px;
    height: 128px;
    border-radius: 10px;
    border: 1px solid #1BC000;
    cursor: pointer;
    color: #5A5A5A;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    /* Enable text ellipsis for the overflow */
    text-overflow: ellipsis;
    /* Show ellipsis only on the last line */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

#alert-with-entity .alert-picking-elements .buttons-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
}

#alert-with-entity .alert-picking-elements .close-button {
    padding: 10px 30px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    cursor: pointer;
}

#alert-with-entity .alert-picking-elements .identify-more {
    padding: 10px 10px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
}

#alert-with-entity iframe {
    pointer-events: none;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    #alert-with-entity .alert-picking-elements {
        width: 300px;
    }
}