#verification-element .desktop {
    margin: 29px auto;
    gap: 44px;
    flex-direction: column;
}

#verification-element .mobile {
    margin: 29px 20px;
}

#verification-element .mobile .verification-element-mobile {
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    background: #00A14C;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;

}

#verification-element .mobile .verification-element-mobile .first-flex {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



@media only screen and (min-width: 768px) {
    #verification-element .desktop {
        display: flex;
    }

    #verification-element .mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #verification-element .desktop {
        display: none;
    }

    #verification-element.mobile {
        display: flex;

    }
}