.login-input-widget {
    border: 1px solid #00A14C;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: #00A14C;
    padding: 0px 10px;
}
.login-input-widget .input-login-style{
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 10px;
}
.login-input-widget .input-login-style:focus{
    border: none;
}
.login-input-widget .visibility{
    margin-right: 5px;
}