#create-relation-buttons .flex-buttons-web {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
    gap: 10px;
}

#create-relation-buttons .flex-buttons-web .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 17px;
    border: 1px solid #00A14C;
    border-radius: 10px;
    color: #000;
    cursor: pointer;

}

#create-relation-buttons .flex-buttons-web .active {
    background: #00A14C;
    color: #FFF;
}

#create-relation-buttons .flex-buttons-mobile {
    display: none;
    align-items: center;
    justify-content: center;
}
#create-relation-buttons .flex-buttons-mobile select{
    width: 200px;
    height: 50px;
    padding: 10px 10px;
    margin-top: 20px;
}



@media only screen and (max-width: 768px) {
    #create-relation-buttons .flex-buttons-web {
        display: none;
    }

    #create-relation-buttons .flex-buttons-mobile {
        display: flex;
    }
}