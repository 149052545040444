.main-create-project {
    width: 198px;
    height: 254px;
    background: #FFFFFF;
    border: 1px solid #00A14C;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
}

.main-create-project .create-project-title {
    background: #00A14C;
    border-bottom: 1px solid rgba(0, 161, 76, 0.5);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    color: #F8F8F8;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
}

.main-create-project .create-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4ECB71;
    height: 209px;
    text-decoration: none;
}

.main-create-project .create-icon .icon-create {
    font-size: 100px;
    cursor: pointer;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .main-create-project {
        display: none;

    }
    .tn{
        display: none;
    }
}