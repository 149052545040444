#relationship-explanation {
    width: 70%;
    margin: auto;
}

#relationship-explanation .page-title {
    margin-top: 20px;
    color: #00A14C;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

#relationship-explanation .save-button-relation-explanation {
    padding: 10px 30px;
    margin: 100px auto 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A14C;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: fit-content;
    color: #FFF;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    font-family: Inter;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #relationship-explanation {
        width: 100%;
        margin: auto;
    }

    #relationship-explanation .page-title {
        color: #FFF;
        background-color: #00A14C;
        font-size: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        margin-top: 0px;
    }

    #relationship-explanation .explanation-relation {
        width: 90% !important;
        margin: 40px auto;
        box-sizing: border-box;
    }
}