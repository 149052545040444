#app_bar_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 105px;
    padding-right: 100px;
    border-bottom: 2px solid #00A14C;
    border-radius: 0px 0px 10px 20px;
}

#app_bar_login .help-and-icon {
    display: flex;
    align-items: center;
}

#app_bar_login .help-and-icon .help {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-right: 20px;
    cursor: pointer;
}

#app_bar_login .help-and-icon .icon {
    color: #00A14C;
    font-size: 40px;
}
#app_bar_login img{
   height: 80px;
   width: 80px;
   margin-left: 30px;
   border-radius: 50%;
}

@media only screen and (max-width: 768px) {
    #app_bar_login {
        padding-right: 30px;
    }
}