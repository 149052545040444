#page-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#page-not-found .image {
    margin-top: 100px;
    width: 60%;
}
#page-not-found .text {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  width: 90%;
}
#page-not-found  .button{
    width: 120px;
    height: 50px;
    border-radius: 10px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    #page-not-found .image {
        margin-top: 40px;
        width: 90%;
    }
}