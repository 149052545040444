#create-flow {
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

#create-flow .box {
    display: flex;
    min-width: 155px;
    max-width: 155px;
    height: 174px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    flex-direction: column;
    white-space: nowrap;
}

#create-flow::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width as per your preference */
}

#create-flow::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Adjust the color of the thumb */
}

#create-flow::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Adjust the color of the track */
}

#create-flow .box .box-header {
    width: 155px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A14C;
    border-radius: 9px 9px 0px 0px;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
}

#create-flow .box .entity {
    width: 155px;
    height: 100%;
    text-align: center;
    overflow: hidden;
    color: #5A5A5A;
    text-overflow: ellipsis;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
}

#create-flow .box .entity img,
#create-flow .box .entity audio,
#create-flow .box .entity video,
#create-flow .box .entity .link-entity-parser {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#create-flow .arrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 155px;
    height: 3px;
    background-color: #00A14C;
    margin-right: 3px;
    position: relative;
    white-space: nowrap;
}

#create-flow .arrow .icon {
    margin-right: -10px;
    color: #00A14C;
}

#create-flow .arrow .input-flow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: -17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 60%;
    transition: 1s all;
    opacity: 1;
}

#create-flow .hide-for-now {
    opacity: 0;
    cursor: none;
    user-select: none;
}

#flow-screen .alert-picking-elements {
    position: fixed;
    z-index: 3;
    width: 600px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #1BC000;
}

#flow-screen .alert-picking-elements .select-title {
    margin-top: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #00A14C;
    display: flex;
    justify-content: center;
}

#flow-screen .alert-picking-elements .box-element {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    gap: 10px;
    margin-top: 40px;
    min-width: 150px;
}

#flow-screen .alert-picking-elements .box-element img,
#flow-screen .alert-picking-elements .box-element audio,
#flow-screen .alert-picking-elements .box-element video,
#flow-screen .alert-picking-elements .box-element .link-entity-parser,
#flow-screen .alert-picking-elements .box-element .link-not-found {
    width: 100%;
    height: 100%;
    width: 110px;
    height: 128px;
    border-radius: 10px;
    border: 1px solid #1BC000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#flow-screen .alert-picking-elements .box-element h1 {
    width: 100%;
    height: 100%;
    width: 110px;
    height: 128px;
    border-radius: 10px;
    border: 1px solid #1BC000;
    cursor: pointer;
    color: #5A5A5A;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#flow-screen .alert-picking-elements .buttons-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
}

#flow-screen .alert-picking-elements .close-button {
    padding: 10px 30px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    cursor: pointer;

}

#flow-screen .alert-picking-elements .identify-more {
    padding: 10px 10px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
}

#flow-screen .save-button {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    background-color: #00A14C;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border-radius: 10px;
    float: right;
    margin-top: 40px;
}

#flow-screen .add-more-flow {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    background-color: #00A14C;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

#flow-screen .add-more-flow:hover {
    background-color: #1be579;
    cursor: pointer;
}

@media only screen and (max-width: 1108px) {
    #create-flow .arrow .input-flow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: -17px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        width: 60%;
        min-width: 100px;
    }
}


@media only screen and (max-width: 768px) {
    #flow-screen .save-button {
        float: none;
        margin: 30px auto;
    }

    #create-flow {
        flex-direction: column;
        margin-top: 30px;
    }

    #create-flow .box {
        width: 155px;
        height: 109px;
    }

    #create-flow .arrow {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 3px;
        max-width: 3px;
        min-width: 3px;
        height: 79px;
        background-color: #00A14C;
        margin-left: 10px;
        margin-bottom: 2px;
    }

    #create-flow .arrow .icon {
        margin-bottom: -10px;
        color: #00A14C;
        transform: rotate(90deg);
    }

    #create-flow .arrow .input-flow {
        position: absolute;
        top: 50%;
        left: 80px;
        overflow: hidden;
        color: #5A5A5A;
        text-overflow: ellipsis;
        font-size: 12px;
        transform: translate(-50%, -50%);
    }

    #create-flow .alert-picking-elements {
        width: 300px;
        top: 50%;
    }


    #create-flow .box .entity img,
    #create-flow .box .entity audio,
    #create-flow .box .entity video,
    #create-flow .box .entity .link-entity-parser {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


}

@media only screen and (max-width: 768px) {
    #flow-screen .alert-picking-elements {
        width: 300px;
    }
}


@media only screen and (min-width: 768px) {
    #create-flow {
        overflow-x: scroll;
        overflow-y: visible;
        height: 500px;
    }
}