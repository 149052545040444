#page_circle_progress {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0px;
    z-index: 21;
    display: none;
    align-items: center;
    justify-content: center;
}

#page_circle_progress .circle-moving {
    margin: auto;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border-bottom: 3px solid #00A14C;
    animation: CircleProgressIndicator 2s infinite Linear;

}

@keyframes circle-animation-page {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}