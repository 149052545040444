#forgot-password {
    width: 773px;
    height: 509px;
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
    margin: 72px auto;
}

#forgot-password .forgot-box-title {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    padding-top: 56px;
    padding-bottom: 69px;
    text-align: center;
}

#forgot-password .step-holder {
    margin: auto;
    width: 297px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#forgot-password .step-holder .input-title {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    text-align: start;
}

#forgot-password .step-holder .back-link {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}

#forgot-password .step-holder .back-link .resend {
    color: #F00;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 3px;
}

#forgot-password .step-holder .button {
    border-radius: 4px;
    background: #00A14C;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: 60px auto 0px;
    cursor: pointer;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
#forgot-password .step-holder .space {
    height: 20px;
}

@media only screen and (max-width: 768px) {
    #forgot-password {
        background: #FFFFFF;
        box-shadow: none;
        margin: 12px auto;
        width: 100%;
    }

    #forgot-password .forgot-box-title {
        font-size: 20px;
        padding-top: 26px;
        padding-bottom: 29px;
    }
}