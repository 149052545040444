.main-flow-title {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 37px 0px;
}

.main-flow-title .flow-title-text {
    color: #00A14C;
    font-size: 36px;
    font-family: Inter;
    font-weight: 700;
    display: flex;
    align-items: center;
}


.main-flow-title .flow-create-new {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.main-flow-title .create-new {
    color: #FFF;
    font-size: 32px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.main-flow-title .create-new .back {
    color: #FFF;
    margin-right: 20px;
    font-size: 32px;
    font-weight: 600;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .main-flow-title .create-new {
        color: #00A14C;
    }

    .main-flow-title .create-new .back {
        color: #00A14C;
    }
}

@media only screen and (max-width: 768px) {

    .main-flow-title {
        background-color: #00A14C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 11px;
        padding: 17px 0px;
    }

    .main-flow-title .flow-title-text {
        color: white;
        padding: 0px;
    }

    #mobile_appbar {
        position: relative;
        display: flex;
        z-index: 99;

    }

    .main-flow-title .flow-title-text .add-icon {
        display: none;
    }
}