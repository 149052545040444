#sentence {
    width: 70%;
    margin: 30px auto;
}

#sentence .project-name {
    margin-top: 10px;
}

#sentence .save-button-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#sentence .save-button-title .save-button {
    padding: 10px 30px;
    background-color: #00A14C;
    color: white;
    border-radius: 30px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #sentence {
        width: 100%;
        margin: 0px auto;
    }

    #sentence .project-name {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    #sentence .save-button-title {
        background: #00A14C;
        height: 70px;
    }
    #sentence .save-button-title .save-button{
        font-weight: 700;
        padding: 10px 30px;
        box-sizing: border-box;
        background-color: white;
        color: #00A14C;
        margin-right: 5px;
    }
}