#mobile-verification-emty .text {
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #00A14C;
    width: 90%;
    margin: 100px auto;
}

#mobile-verification-emty .create-now {
    color: #FFF;
    background-color: #00A14C;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 10px auto;
    border-radius: 10px;
    cursor: pointer;
}

#mobile-verification-emty .back {
    color: #FFF;
    background-color: red;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 10px auto;
    border-radius: 10px;
    cursor: pointer;
}