#circle-progress-indicator {
    position: relative;
    margin: auto;
}

#circle-progress-indicator .progress__circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #00A14C;
    position: relative;
    overflow: hidden;
    animation: CircleProgressIndicator 3s infinite Linear;
}

#circle-progress-indicator .progress__circle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background-color: white;
    transform: rotate(0deg);
    transform-origin: center center;
    z-index: 1;
}

@keyframes CircleProgressIndicator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}