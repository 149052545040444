.linear-box-element {
    border-radius: 10px;
    border: 1px solid #00A14C;
    width: 198px;
    height: 254px;
    cursor: pointer;
}

.linear-box-element .linear-blox-header {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
}

.linear-box-element .linear-blox-header h1 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.linear-box-element .linear-blox-header .delete{
    color: red;
    background-color: white;
    border-radius: 50%;
    padding: 3px;
}

.linear-box-element .linear-blox-header .icon-holder {
    color: white;
    position: relative;
}

.linear-box-element .linear-blox-header .icon-holder .edite-delete {
    display: none;
    position: absolute;
    top: 100px;
}

.linear-box-element .linear-blox-header .icon-holder:hover .edite-delete {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 4px;
    flex-direction: column;
    border: 1px solid #E1E1E1;
    background: #F8F8F8;
    width: 47px;
    height: 64px;
    top: 0px;
    left: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.linear-box-element .linear-blox-header .icon-holder:hover .edite-delete h1 {
    color: #5A5A5A;
    font-size: 10px;
    font-weight: 600;
    padding-left: 5px;
    cursor: pointer;
}

.linear-box-element .linear-content {
    text-align: start;
    overflow: hidden;
    color: #5A5A5A;
    text-overflow: ellipsis;
    font-size: 12px;
    height: 70%;
    padding: 10px;

}

.linear-box-element .linear-date {
    color: rgba(90, 90, 90, 0.50);
    font-size: 15px;
}
.linear-box-element .active {
    color: #1BC000  !important;
}

.linear-box-element .not-active {
    color: #FF0000 !important;
}