#create-relation-element {
    position: relative;
}

#create-relation-element .title-create {
    margin-top: 40px;
    color: #00A14C;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}

#create-relation-element .title-create .close {
    color: red;
    font-size: 40px;
    margin-top: 3px;
    margin-left: 10px;
    cursor: pointer;
}

#create-relation-element .relation-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    margin: 30px auto;
}

#create-relation-element .relation-box .box-holder {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 46px;
    flex-direction: column;

}

#create-relation-element .relation-box .box-holder .box {
    width: 128px;
    height: 177px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    background: #FFF;
    transition: 1s opacity;
    opacity: 0;
    cursor: none;
    pointer-events: none;
}

#create-relation-element .show {
    opacity: 1 !important;
    cursor: pointer !important;
    pointer-events: all !important;
}


#create-relation-element .relation-box .box-holder .box .title {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    width: 128px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #FFF;
}

#create-relation-element .relation-box .box-holder .box .content {
    height: 133px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

#create-relation-element .relation-box .box-holder .box .content .holder {
    height: 133px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

#create-relation-element .relation-box .box-holder .box .entityes {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 5px;
}

#create-relation-element .relation-box .box-holder .box .content p {
    margin: 6px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

#create-relation-element .relation-box .box-holder .box .content img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

#create-relation-element .relation-box .arrow {
    width: 155.018px;
    height: 223px;
    position: relative;
    transition: 1s all;
    opacity: 0;
    cursor: auto !important;

}

#create-relation-element .relation-box .arrow .notrelated {
    position: absolute;
    top: 65px;
    left: 32px;
    font-size: 105px;
    color: red;
}

#create-relation-element .relation-box .third-entity {
    border-radius: 10px;
    border: 1px solid #00A14C;
    background: #FFF;
    width: 98px;
}

#create-relation-element .relation-box .third-entity .title {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #FFF;
    width: 100%;
    font-size: 14px;
    text-align: center;
}

#create-relation-element .relation-box .third-entity .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    max-height: 60px;
    min-height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    overflow: hidden;
}

#create-relation-element .relation-box .third-entity input {
    border: none;
    outline: none;
    padding: 10px;
}


#create-relation-element .relation-box .buttons-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}

#create-relation-element .relation-box .buttons-type .addthird-button {
    background-color: #00A14C;
    border-radius: 10px;
    color: #FFF;
    white-space: nowrap;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
}

#relationship-screen .relationship-answer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#relationship-screen .content img,
#relationship-screen .content audio,
#relationship-screen .content video,
#relationship-screen .content .link-entity-parser {
    width: 100%;
    height: 100%;

}

#relationship-screen .save-relation-buttom {
    width: 150px;
    height: 50px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    float: right;
    margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {
    #create-relation-element .relation-box .third-entity .title {
        font-size: 14px !important;
        text-align: center;
    }

    #create-relation-element {
        margin-left: 10px;
    }

    #create-relation-element .title-create {
        font-size: 30px;
        white-space: nowrap;
    }

    #create-relation-element .title-create .close {
        color: red;
        font-size: 30px;
        margin-top: 3px;
        margin-left: 5px;
        cursor: pointer;
    }

    #create-relation-element .relation-box .third-entity {
        border-radius: 10px;
        border: 1px solid #00A14C;
        background: #FFF;
    }

}

@media only screen and (max-width: 468px) {
    #create-relation-element .relation-box .box-holder .box {
        width: 108px;
    }

    #create-relation-element .relation-box .box-holder .box .title {
        width: 108px;
    }
}