.sentence-write-place {
    position: relative;
}

.sentence-write-place .top-border-sentence {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #5A5A5A;
    cursor: pointer;
    right: 10px;
    top: 0px;
    background-color: transparent;
}

.sentence-write-place .top-border-sentence .add-icons {
    font-size: 40px;
    margin-right: 20px;
}

.sentence-write-place .button-active {
    color: #00A14C !important;
}

.sentence-write-place .top-border-sentence .identify-word-text-button {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

#text-area-box {
    width: 100%;
    height: 300.89px;
    background: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    text-align: start;
    vertical-align: top;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #5A5A5A;
    resize: none;
    margin-top: 50px;
    margin-bottom: 50px;
    box-sizing: border-box;
}

#text-area-box::selection .selection-alert {
    background-color: red;
    width: 100px;
    height: 40px;
    position: absolute;
    top: 30px;

}

.identified-word-box {
    margin-top: 30px;
    width: 315px;
    background: #F8F8F8;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.identified-word-box .identifyed-word-title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.identified-word-box .identifyed-words {
    margin-left: 20px;
    margin-top: 40px;
    position: relative;
}

.remove-word {
    color: red;
    position: absolute;
    right: 10px;
    bottom: -4px;
    cursor: pointer;
}

.identified-word-box .identifyed-word-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5A5A5A;
    overflow: hidden;
}

.identifyed-word-divider {
    margin-top: 10px;
    width: 253px;
    border: 1px solid #31769B;
    margin-bottom: 15px;
}

#selection-alert {
    position: absolute;
    display: none;
    padding: 5px;
    background-color: yellow;
    border: 1px solid black;
    font-size: 12px;
}

.identifyed-word-holder-sentence {
    display: flex;

}

.identifyed-word-holder-sentence .button {
    width: 150px;
    height: 48px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    margin-top: 50px;
    margin-left: 40px;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .sentence-write-place {
        width: 80%;
        margin: 30px auto 0px;
    }

    .sentence-write-place .top-border-sentence {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #5A5A5A;
        cursor: pointer;
        right: 0px;
        top: -40px;
        background-color: transparent;
    }

    .sentence-write-place .sentence-text-place {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-decoration: none;
        height: 155px;
        color: #5A5A5A;
    }

    .identified-word-box {
        max-width: 315px;
        width: 100%;
        margin: 80px auto;
        background: #F8F8F8;
        border: 1px solid #E1E1E1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

}