.sentence_title .sentence-title-text {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #00A14C;
}


@media only screen and (max-width: 768px) {
    .sentence_title {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sentence_title .sentence-title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #FFFFFF;
        margin-left: 20px;
    }
}