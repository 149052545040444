#report-screen {
    width: 75%;
    margin: 34px auto;
}

#report-screen .divider {
    width: 50%;
    height: 2px;
    background-color: #1BC000;
}


#report-screen .title {
    color: #5A5A5A;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 120px;
}

#report-screen .sentence {
    color: #5A5A5A;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

#report-screen .before-infromation {
    color: #5A5A5A;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 4px;
    padding-bottom: 5px;
}

#report-screen .information {
    color: #5A5A5A;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 5px;
    margin: 5px 0px;
}


#report-screen .line-between-verifications {
    width: 100%;
    height: 3px;
    background-color: rgba(116, 113, 113, 0.352);
    border-radius: 10px;
}

#report-screen .entity-word {
    color: #31769B !important;
}


#report-screen .project-name .content {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    width: 100%;
}

#report-screen .project-name .content .titles {
    flex: 1;
}

#report-screen .project-name .content .informations {
    flex: 3;
}


#report-screen span {
    color: #1BC000;
}

#report-screen .active {
    color: #1BC000 !important;
}

#report-screen .not-active {
    color: #FF0000 !important;
}

#report-screen .yes {
    margin-left: 20px;
    margin-top: 5px;
}

#report-screen .main-info-flow {
    padding: 5px 0px;
}

@media only screen and (max-width: 768px) {
    #report-screen {
        width: 100%;
        margin: auto;
    }

    #report-screen .project-name {
        padding: 20px;
    }

    #report-screen .title {
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        white-space: normal;
        min-width: min-content;
    }

    #report-screen .before-infromation {
        font-size: 12px;
        padding-top: 4px;
        padding-bottom: 5px;
    }

    #report-screen .information {
        font-size: 12px;
        padding-bottom: 5px;

    }

    #report-screen .sentence {
        margin-top: 20px;
        line-height: 20px;
    }

    #report-screen .project-name .content .titles {
        flex: 1;
    }

    #report-screen .project-name .content .informations {
        flex: 4 !important;
    }

    #report-screen .flow-content {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

    }
    #report-screen .yes {
        font-size: 12px;
        margin: 5px 0px;
    }
}

.line {
    width: 100%;
    height: 1px;
    background-color: #5A5A5A;
}
.last-space-before-line{
    margin-bottom: 10px;
}