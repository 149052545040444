#entity-widget {
    border-radius: 10px;
    box-shadow: 0 0 5px gray;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

#entity-widget .entity-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
#entity-widget .entity-link a{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
}

#entity-widget .entity-audio {
    width: 100%;
    height: 80%;
}

#entity-widget .entity-video {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
#entity-widget .entity-video img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
#entity-widget img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
#entity-widget .link-not-found{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}