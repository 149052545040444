#project .project-map {
    width: 90%;
    margin-left: 20px;
    display: flex;
    row-gap: 20px;
    margin-top: 30px;
    margin: 30px auto 0px;
    column-count: 2;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: center;
    gap: 20px;
    position: relative;
}

#project .project-list {
    width: 70%;
    margin: 30px auto;
}

#project .alert-create-project {
    position: fixed;
    width: 300px;
    height: 250px;
    top: 50%;
    left: 50%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 10;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
}

#project .alert-create-project .buttons-holder-create-project {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#project .alert-create-project .buttons-holder-create-project .create-project {
    background-color: #00A14C;
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

#project .alert-create-project .buttons-holder-create-project .cancel-create-project {
    background-color: red;
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

#project .alert-create-project .project-type {
    width: 100%;
    height: 50px;
    border: 1px solid #00A14C;
    border-radius: 4px;
}

#delete-item {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.362);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-item .alert-mesg {
    width: 50%;
    height: 200px;
    background-color: white;
    border-radius: 20px;
}

#delete-item .alert-mesg h1 {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    color: black;
}

#delete-item .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
}

#delete-item .buttons .bt {
    padding: 5px 10px;
    background-color: #00A14C;
    border-radius: 10px;
    cursor: pointer;
    color: white;
}

#delete-item .buttons .close {
    background-color: red;
}

@media only screen and (max-width: 768px) {
    #project .project-list {
        width: 100%;
        margin: 30px auto;
    }

    #delete-item .alert-mesg {
        width: 80%;
    }
}

#list-create {
    margin-top: 10px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: #00A14C;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
@media only screen and (max-width: 470px) {
    #project .project-map {
        justify-content: center;
    }
    #list-create{
        display: none;
    }
}

