#verification-title {
    width: 75%;
    margin: 52px auto 0px;
}

#verification-title .desktop h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#verification-title .desktop h1 .icon {
    font-size: 50px;
    color: #00A14C;
    margin-left: 10px;
    cursor: pointer;
}

#verification-title .desktop .buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    padding: 30px 0px;
}


#verification-title .desktop .buttons .buttons-element {
    height: 51px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    cursor: pointer;
    position: relative;
}

#verification-title .desktop .buttons .buttons-element span {
    display: none;
}

#verification-title .desktop .buttons .buttons-element:hover span {
    padding: 5px 30px;
    display: block;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%,-40%);
}

#verification-title .desktop .buttons .buttons-element:hover span::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

#verification-title .desktop .buttons .verification-button-active {
    background-color: #00A14C;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

#verification-title .desktop h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #00A14C;
}

#verification-title .mobile {
    background: #00A14C;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 13px;
}

#verification-title .mobile h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #FFFFFF;
}

#verification-title .mobile h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
}



@media only screen and (min-width: 768px) {
    #verification-title .desktop {
        display: block;
    }

    #verification-title .mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #verification-title .desktop {
        display: none;
    }

    #verification-title .mobile {
        display: flex;

    }

    #verification-title {
        width: 100%;
        margin: 12px auto 0px;

    }
}