.project-element-holder {
    width: 198px;
    height: 254px;
    background: #FFFFFF;
    border: 1px solid #00A14C;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.active-project {
    box-shadow: 0px 4px 4px #00A14C;
}

.project-element-holder .project-name-menu {
    background: #00A14C;
    border-radius: 9px 9px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0px 5px;
}

.project-element-holder .project-name-menu .project-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    text-overflow: ellipsis;
    flex: 20;
    max-lines: 2;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 20px;
}

.project-element-holder .project-name-menu .project-menu {
    color: white;
    position: relative;
    flex: 1;
}

.project-element-holder .project-name-menu .project-menu .buttons-holder {
    display: none
}

.project-element-holder .project-name-menu .project-menu:hover .buttons-holder {
    display: flex;
    left: -20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    box-sizing: border-box;
    position: absolute;
    width: 67px;
    height: 94px;
    background: #F8F8F8;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.project-element-holder .project-name-menu .project-menu:hover .buttons-holder .buttons-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #5A5A5A;
    padding-left: 6px;
    cursor: pointer;
}

.project-element-holder .project-name-menu .project-menu:hover .buttons-holder .space-text {
    height: 10px;
}

.project-element-holder .sentence-box {
    height: min-content;
    width: 90%;
    margin: 5px auto;
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5A5A5A;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    box-sizing: border-box;
    min-height: 75px;
}

.project-element-holder .time-created {
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(90, 90, 90, 0.5);
    text-align: center;
}

.project-element-holder .count,
.project-element-holder .identifyed,
.project-element-holder .entity {
    margin-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: black;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    /* .project-element-holder {
        width: 198px;
        height: 254px;
    }

    .project-element-holder .sentence-box {
        width: 163px;
        height: 159px;
    }

    .project-element-holder .time-created {
        margin-top: -5px;
    } */
}