#show-full-alert-widget {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    justify-content: center;
    align-items: center;
    background-color: rgb(105, 103, 103);
    opacity: 0.9;
    z-index: 22;
}

#show-full-alert-widget .box-content {
    padding: 30px;
    width: 60%;
    height: 40%;
    background: #FFFFFF;
    position: relative;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
    background-color: white;
    opacity: 1;
}

#show-full-alert-widget .box-content #text-for-full-alert-widget {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #00A14C;
}

#show-full-alert-widget .box-content .icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color: red;
}