#create-linear {
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

#create-linear .box {
    display: flex;
    min-width: 155px;
    max-width: 155px;
    height: 174px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    flex-direction: column;
    white-space: nowrap;
}

#create-linear::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width as per your preference */
}

#create-linear::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Adjust the color of the thumb */
}

#create-linear::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Adjust the color of the track */
}

#create-linear .box .box-header {
    width: 155px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A14C;
    border-radius: 9px 9px 0px 0px;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
}

#create-linear .box .entity {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    max-lines: 10;
    color: #5A5A5A;
    text-overflow: ellipsis;
    font-size: 14px;
    cursor: pointer;
    white-space: normal !important;
}

#create-linear .arrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 155px;
    height: 3px;
    background-color: #00A14C;
    margin-right: 3px;
    position: relative;
    white-space: nowrap;
}

#create-linear .arrow .icon {
    margin-right: -10px;
    color: #00A14C;
}

#create-linear .buttons {
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

#create-linear .space {
    margin-left: 100px;
}

#create-linear .buttons div {
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
}

#create-linear .red {
    background-color: red;
}

#create-linear .green {
    background-color: #00A14C;
}


#create-linear .arrow .input-flow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: -17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 60%;
    transition: 1s all;
    opacity: 1;
}

#create-linear .hide-for-now {
    opacity: 0;
    cursor: none;
    user-select: none;
}

#main-linear .alert-picking-elements {
    position: fixed;
    z-index: 3;
    width: 600px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #1BC000;
}

#main-linear .alert-picking-elements .select-title {
    margin-top: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #00A14C;
    display: flex;
    justify-content: center;
}

#main-linear .alert-picking-elements .box-element {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    gap: 10px;
    margin-top: 40px;
    min-width: 150px;
}

#main-linear .alert-picking-elements .box-element h1 {
    width: 100%;
    height: 100%;
    width: 110px;
    height: 128px;
    border-radius: 10px;
    border: 1px solid #1BC000;
    cursor: pointer;
    color: #5A5A5A;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#main-linear .alert-picking-elements .buttons-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
}

#main-linear .alert-picking-elements .close-button {
    padding: 10px 30px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    cursor: pointer;

}

#main-linear .alert-picking-elements .identify-more {
    padding: 10px 10px;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
}

#main-linear .save-button {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    background-color: #00A14C;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border-radius: 10px;
    float: right;
    margin-top: 40px;
}

#main-linear .add-more-flow {
    width: fit-content;
    padding: 0px 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    background-color: #00A14C;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

#main-linear .add-more-flow:hover {
    background-color: #1be579;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #main-linear .save-button {
        float: none;
        margin: 30px auto;
    }

    #create-linear {
        flex-direction: column;
        margin-top: 30px;
    }

    #create-linear .box {
        width: 155px;
        height: 109px;
    }

    #create-linear .arrow {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 3px;
        max-width: 3px;
        min-width: 3px;
        height: 79px;
        background-color: #00A14C;
        margin-left: 10px;
        margin-bottom: 3px;
    }

    #create-linear .arrow .icon {
        margin-bottom: -10px;
        color: #00A14C;
        transform: rotate(90deg);
    }

    #create-linear .arrow .input-flow {
        position: absolute;
        top: 50%;
        left: 50px;
        color: #5A5A5A;
        text-overflow: ellipsis;
        font-size: 12px;
        transform: translate(-50%, -50%);
    }

    #create-linear .alert-picking-elements {
        width: 300px;
        top: 50%;
    }

}

@media only screen and (max-width: 768px) {
    #main-linear .alert-picking-elements {
        width: 300px;
    }
}


@media only screen and (min-width: 768px) {
    #create-linear {
        overflow-x: scroll;
        overflow-y: visible;
        height: 500px;
    }
}
#create-linear .close-button-arrow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: red;
}