.main-verification-box {
    background: #FFFFFF;
    border: 1px solid #00A14C;
    border-radius: 10px;
    margin: auto;
}

.main-verification-box .top-verification {
    height: 78px;
    background: #00A14C;
    border-radius: 9px 9px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 60px;
}

.main-verification-box .top-verification h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
}

.main-verification-box .box-content {
    padding-left: 25px;
    padding-right: 25px;

}

.main-verification-box .box-content .time {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.main-verification-box .box-content .word-type-entity-holder {
    display: flex;
    gap: 10px;
    height: 271px;
    width: 100%;
    align-items: center;
}

.main-verification-box .box-content .word-type-entity-holder .word {
    flex: 1.2;
    max-lines: 3;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

}

.main-verification-box .box-content .word-type-entity-holder .type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #5A5A5A;
    position: relative;
}
.main-verification-box .box-content .word-type-entity-holder .type .not-related {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;
    color: red;
}

.main-verification-box .box-content .word-type-entity-holder .type h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #5A5A5A;

}

.main-verification-box .box-content .word-type-entity-holder .type .line-arrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.main-verification-box .box-content .word-type-entity-holder .type .line-arrow .line {
    width: 100%;
    height: 3px;
    background-color: #00A14C;
}

.main-verification-box .box-content .word-type-entity-holder .type .line-arrow .arrow {
    font-size: 30px;
    margin-left: -17px;
    color: #00A14C;
}


.main-verification-box .box-content .word-type-entity-holder .entity {
    flex: 2;
    border-radius: 10px;
    width: 171px;
    height: 197px;
    border: 1px solid #00A14C;
}

.main-verification-box .box-content .word-type-entity-holder .one-entity {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    width: 171px;
    height: 197px;
    flex: 2;
    border: 1px solid #00A14C;
}

.main-verification-box .box-content .word-type-entity-holder .entity img,
.main-verification-box .box-content .word-type-entity-holder .entity video,
.main-verification-box .box-content .word-type-entity-holder .entity audio,
.main-verification-box .box-content .word-type-entity-holder .entity .link-entity-parser,
.main-verification-box .box-content .word-type-entity-holder .one-entity img,
.main-verification-box .box-content .word-type-entity-holder .one-entity video,
.main-verification-box .box-content .word-type-entity-holder .one-entity audio,
.main-verification-box .box-content .word-type-entity-holder .one-entity .link-entity-parser {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 9px;
}