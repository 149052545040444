#infromation-flow-screen {
    width: 70%;
    margin: auto;
}

#infromation-flow-screen .flow-elements-holder {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

#infromation-flow-screen .flow-elements-holder .create-flow-element {
    border-radius: 10px;
    border: 1px solid #00A14C;
    width: 198px;
    height: 254px;
}

#infromation-flow-screen .flow-elements-holder .create-flow-element .create-flow-element-top {
    width: 198px;
    height: 46px;
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    color: #F8F8F8;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

#infromation-flow-screen .flow-elements-holder .create-flow-element .create-flow-element-bottom {
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#infromation-flow-screen .flow-elements-holder .create-flow-element .create-flow-element-bottom .icon {
    font-size: 120px;
    color: #4ECB71;
    cursor: pointer;
}

#infromation-flow-screen .flow-message {
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
}

#infromation-flow-screen .flow-message div {
    font-size: 25px;
    margin-top: 30px;
    text-align: center;

}

#infromation-flow-screen .flow-message div a {
    color: #00A14C;
    text-decoration: none;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    #infromation-flow-screen {
        width: 100%;
    }

    #infromation-flow-screen .flow-message {
        font-size: 18px;
        margin-top: 30px;
        text-align: center;
    }

    #infromation-flow-screen .flow-elements-holder {
        margin-top: 30px;
        padding: 0px 20px;
    }

}
@media only screen and (max-width: 470px) {
    #infromation-flow-screen .flow-elements-holder {
        justify-content: center;
    }

}