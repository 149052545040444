#set-principle {
    width: 70%;
    margin-bottom: 200px;
    margin: auto;
}

#set-principle .principle-element-top {
    padding: 17px 0px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #00A14C;
    color: #00A14C;
    font-size: 13px;
    font-weight: 600;
    text-decoration-line: underline;
}

#set-principle .principle-element-top .title {
    flex: 1;
}

#set-principle .principle-element-top .body {
    flex: 2;
}

#set-principle .principle-element-top .content {
    flex: 1;
    text-align: center;
}

#set-principle .principle-content {
    color: #5A5A5A;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 17px 0px;
    border-bottom: 2px solid #00A14C;
}

#set-principle .principle-content .title {
    flex: 1;
}

#set-principle .principle-content .title h1 {
    padding-left: 50px;
    color: #5A5A5A;
    font-size: 12px;
    font-weight: 600;
}

#set-principle .principle-content .body {
    flex: 2;
}

#set-principle .principle-content .content {
    flex: 1;
    text-align: center;
}

#set-principle .principle-content input {
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

#set-principle .principle-content input[type="checkbox"]:checked {
    accent-color: #00A14C;
}

#set-principle .button {
    padding: 0px 7px;
    background-color: #00A14C;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin-top: 30px;
    float: right;
    border-radius: 10px;
    cursor: pointer;
}

#set-principle .drived-prinicple-holder .drived-principle-items {
    border-top: 3px solid #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

#set-principle .drived-prinicple-holder .drived-principle-items .items-drived {
    display: flex;
    padding: 10px 0px;
    border-bottom: 2px solid #00A14C;
    width: 100%;
    align-items: center;
    position: relative;
}

#set-principle .drived-prinicple-holder .drived-principle-items .items-drived .index {
    color: #5A5A5A;
    font-size: 20px;
    font-weight: 600;
    padding-left: 60px;
}

#set-principle .drived-prinicple-holder .drived-principle-items .items-drived .value {
    color: #5A5A5A;
    font-size: 20px;
    font-weight: 600;
    padding-left: 150px;
}

#set-principle .drived-prinicple-holder .drived-principle-items .items-drived .icon {
    display: none;
}

#set-principle .drived-prinicple-holder .explane {
    margin-top: 30px;
    width: 100%;
}

#set-principle .drived-prinicple-holder .explane .text-area {
    width: 100% !important;
    background: #FFF;
    resize: none;
    box-sizing: border-box;

}

#set-principle .drived-prinicple-holder .explane .text-area .ql-toolbar {
    border-radius: 20px 20px 0px 0px;
    border: 0px 0px 1px 0px;
    background: #F5F5F5;

}

#set-principle .drived-prinicple-holder .explane .text-area .ql-container {
    border: 0px 0px 1px 0px;
    background: #FFF;
    border-radius: 0px 0px 20px 20px;
    max-height: 300px;
    min-height: 200px;
}

@media only screen and (max-width: 768px) {
    #set-principle {
        width: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    #set-principle .principle-content .title {
        flex: 1.5;
    }

    #set-principle .principle-element-top .title {
        flex: 1.5;
    }

    #set-principle .drived-prinicple-holder .drived-principle-items .items-drived {
        border-bottom: none;
    }

    #set-principle .drived-prinicple-holder .drived-principle-items .items-drived .index {
        font-size: 15px;
        padding-left: 10px;
    }

    #set-principle .drived-prinicple-holder .drived-principle-items .items-drived .value {
        font-size: 15px;
        padding-left: 11px;

    }

    #set-principle .drived-prinicple-holder .drived-principle-items {
        border: none;
        margin-top: 24px;
        margin: 24px 20px;
    }

    #set-principle .drived-prinicple-holder .drived-principle-items .items-drived .icon {
        float: right;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        color: #00A14C;
        display: block;

    }
    #set-principle .drived-prinicple-holder .drived-principle-items .items-drived span {
        display: none;
    }
    #set-principle .principle-content .body span {
        display: none;
    }

}