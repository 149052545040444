.error-text-linear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 100px auto;
    text-align: center;
    font-size: 30px;
}


@media only screen and (max-width: 768px) {
    .error-text-linear {
        font-size: 18px;
        margin: 50px auto;
        width: 95%;
    }
}