* {
    padding: 0px;
    margin: 0px;
    font-family: Inter, sans-serif;
}

.color {
    color: #00A14C
}

.red {
    color: red;
}

.color-b {
    background-color: #00A14C;
}

.inter-14 {
    font-size: 14px;
    color: black;
    font-weight: 400;
    text-decoration: none;
}

.a {
    text-decoration: none;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tn {
    text-decoration: none;
}

img,
video,
audio {
    object-fit: fill !important;
}

.slick-slide,
.slick-list,
.slick-initialized,
.slick-slide div,
.slick-list div {
    height: 100% !important;
    /* Make slides full viewport height */
}

.slick-dots {
    bottom: 20px !important;
    /* Position the dots inside the images */
}

iframe {
    width: 100%;
    height: 100%;
    border-radius: 9px;
    padding: 1px;
    box-sizing: border-box;
    pointer-events: none;
}