#Alert_with_text_global {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 0px;
    left: 0px;
    display: none;
}

#Alert_with_text_global .backgroud-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.397);
}

#Alert_with_text_global .box-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 30px 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}

#text-in-alert-global {
    flex: 1; /* Allows the child to grow and shrink as needed */
    width: 100%; /* Full width of the parent */
    max-height: 100%; /* Full height of the parent */
    overflow: auto; /* Scroll inside if its own content overflows */
    box-sizing: border-box; 
}

#Alert_with_text_global .box-text .button {
    background-color: #1BC000;
    padding: 10px 15px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    margin-top: 30px;
}