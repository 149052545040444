.swicth-main-holder {
  position: relative;
  z-index: 1;
}
.swicth-main-holder .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border: 1px solid #00A14C;
  border-radius: 34px;
  z-index: 1;
}

.swicth-main-holder  .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 1;
}

.swicth-main-holder  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 1;
}

.swicth-main-holder  .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #00A14C;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 1;
}

.swicth-main-holder input[value='true']+.slider {
  background-color: white;
}

.swicth-main-holder  input:focus+.slider {
  box-shadow: 0 0 3px #00A14C;
}

.swicth-main-holder  input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.swicth-main-holder  .slider.round {
  border-radius: 34px;
  z-index: 1;
}

.swicth-main-holder  .slider.round:before {
  border-radius: 50%;
}