#relationship-screen .relationship-header {
    padding: 40px 0px;
}

#relationship-screen .relationship-header .title-holder {
    display: flex;
    align-items: center;

}

#relationship-screen .relationship-header .title-holder .title-icon {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

#relationship-screen .relationship-header .title-holder .create-new {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
}

#relationship-screen .relationship-header .title-holder .title-icon .title {
    color: #00A14C;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

#relationship-screen .relationship-header .title-holder .title-icon .icon {
    color: #00A14C;
    font-size: 46px;
    margin-left: 10px;
    cursor: pointer;
}

#relationship-screen .relationship-header .title-holder .buttons {
    display: flex;
    align-items: center;
}

#relationship-screen .relationship-header .title-holder .buttons .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #00A14C;
    height: 51px;
    padding: 0px 10px;
    margin: 15px;
    font-size: 16px;
    font-weight: 600;
    color: #00A14C;
}

#relationship-screen .relationship-header .title-holder .buttons .active {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    background: #00A14C;
}


@media only screen and (min-width: 768px) {
    #relationship-screen .relationship-header .title-holder .create-new {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #relationship-screen .relationship-header {
        padding: 0px 0px;
    }

    #relationship-screen .relationship-header .title-holder {
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    #relationship-screen .relationship-header .title-holder .title-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00A14C;
        flex-direction: column;
        width: 100%;
        padding: 15px 0px;
    }

    #relationship-screen .relationship-header .title-holder .title-icon .title {
        color: white;
        font-size: 36px;
        font-weight: 700;
    }

    #relationship-screen .relationship-header .title-holder .title-icon .icon {
        display: none;
    }

    #relationship-screen .relationship-header .title-holder .buttons {
        width: 100%;
        justify-content: space-between;

    }

    #relationship-screen .relationship-header .title-holder .buttons .button {
        flex: 1;
        width: 100%;
        margin: 5px;
        white-space: nowrap;
        max-width: 200px;
    }

}