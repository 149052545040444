.main-project-list {
    display: flex;
    border-bottom: 1px solid #00A14C;
    padding: 13px 0px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.main-project-list .name-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 159px;
    height: 53px;
}

.main-project-list .name-time .name-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}

.main-project-list .name-time .time-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.main-project-list .sentence {
    width: 348px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    padding: 0px 10px;
    font-size: 15px;
    line-height: 18px;
    color: #5A5A5A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.main-project-list .sentence p {
    text-overflow: ellipsis;
}

.main-project-list .delete-edite-icons .edit {
    margin-right: 20px;
    color: #00A14C;
    cursor: pointer;
}

.main-project-list .delete-edite-icons .delete {
    margin-right: 20px;
    color: red;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .main-project-list {
        padding: 13px 10px;
    }
}

@media only screen and (max-width: 768px) {


    .main-project-list .sentence {
        width: 236px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #5A5A5A;
    }

    .main-project-list .delete-edite-icons {
        white-space: nowrap;
    }

    .main-project-list .name-time .name-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        color: #000000;
        text-overflow: ellipsis;
        text-align: center;
        overflow: hidden;
    }

    .main-project-list .name-time .time-text {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: rgba(90, 90, 90, 0.5);
        white-space: nowrap;

    }

    .main-project-list .delete-edite-icons .edit {
        margin: auto;
    }
}