#identify-entity {
    width: 75%;
    margin: 44px auto 100px;
}

#identify-entity .entity-title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #00A14C;
}

#identify-entity .entity-title .icon {
    font-size: 40px;
    margin-left: 20px;
    cursor: pointer;
}

#identify-entity .entity-group-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 59px auto 0px;
}

#identify-entity .create-entity {
    margin-top: 40px;
    display: flex;
    width: 401px;
    height: 228px;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    border: 2px solid #00A14C;
    padding: 10px;
}
#identify-entity .create-entity .sentence-in-entity{
    padding: 10px 5px;
}
#identify-entity .create-entity .entity-name-type {
    display: flex;
    justify-content: space-between;
}

#identify-entity .create-entity .entity-name-type .entity-type {
    width: 150px;
}

#identify-entity .create-entity .files-or-link {
    width: 100%;
}
#identify-entity .create-entity .files-or-link  .note{
    color: gray;
    font-size: 10px;
}

#identify-entity .create-entity .files-or-link label {
    min-width: 150px !important;
    max-width: 352px;
}



#identify-entity .create-entity .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#identify-entity .create-entity .buttons .close {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px 10px;
    color: white;
    border-radius: 10px;
    background-color: red;
    font-size: 14px;
}

#identify-entity .create-entity .buttons .save {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 5px 10px;
    color: white;
    border-radius: 10px;
    background-color: #00A14C;
    cursor: pointer;
}



#identify-entity .entity-group-holder .entity-box {
    width: 301px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#identify-entity .entity-group-holder .entity-box #entity-widget {
    max-width: 130px;
}

#identify-entity .entity-group-holder .entity-box .entity-name {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: max-content;
    gap: 10px;
}

#identify-entity .entity-group-holder .entity-box .entity-name h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5A5A5A;
    text-align: center;
}

#identify-entity .entity-group-holder .entity-box .entity-name .icon {
    margin-left: 20px;
    color: red;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid red;
    cursor: pointer;
}

#identify-entity .entity-group-holder .entity-box .entity {
    border-radius: 10px;
    width: 110px;
    height: 128px;
    background-color: red;

}
#identify-entity iframe{
    pointer-events: all !important;
}


@media only screen and (max-width: 768px) {
    #identify-entity {
        width: 100%;
        margin: 10px auto 30px;
    }

    #identify-entity .entity-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #FFFFFF;
        height: 100px;
        background-color: #00A14C;
    }

    #identify-entity .entity-group-holder {
        flex-direction: column;
        gap: 14px;

    }

    #identify-entity .entity-group-holder .entity-box {
        box-sizing: border-box;
        padding: 0px 30px;
        width: auto;
        height: 128px;
        justify-content: space-between;
        width: 100%;
    }

    #identify-entity .create-entity {
        margin: 40px 10px 0px;
        display: flex;
        width: 95%;
        height: 258px;
        max-width: 400px;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        border: 2px solid #00A14C;
        box-sizing: border-box;
    }

    #identify-entity .entity-group-holder .entity-box .entity-name {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: max-content;
    }

    #identify-entity .entity-group-holder .entity-box .entity-name h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #5A5A5A;
        text-align: center;
    }
    #identify-entity .entity-group-holder .entity-box .entity-name .icon {
        margin-left: 0px;
    }

}




#delete-item {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.362);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-item .alert-mesg {
    width: 50%;
    height: 200px;
    background-color: white;
    border-radius: 20px;
}

#delete-item .alert-mesg h1 {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    color: black;
}

#delete-item .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
}

#delete-item .buttons .bt {
    padding: 5px 10px;
    background-color: #00A14C;
    border-radius: 10px;
    cursor: pointer;
    color: white;
}

#delete-item .buttons .close {
    background-color: red;
}

@media only screen and (max-width: 768px) {
    #delete-item .alert-mesg {
        width: 80%;
    }
}