#relationship-screen {
    width: 70%;
    margin: auto;
}

#relationship-screen .need-entity {
    font-size: 30px;
    margin-top: 50px;
}

#relationship-screen .need-entity div {
    font-size: 30px;
    padding-top: 40px;
    text-decoration: none;
    text-align: center;

}

#relationship-screen .need-entity div a {
    color: #00A14C;
}


@media only screen and (max-width: 768px) {
    #relationship-screen {
        width: 100%;
        margin: auto;
    }

    #relationship-screen .need-entity {
        font-size: 20px;
        margin-top: 50px;
        text-align: center;
    }

    #relationship-screen .need-entity a {
        font-size: 30px;
        margin-top: 50px;
        text-decoration: none;
    }
}