.response-data-failure {
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
    color: #000000;
    margin-top: 50px;
    
}
@media only screen and (max-width: 768px) {
    .response-data-failure {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        color: #000000;
        margin-top: 50px;
        line-height: 40px;
        
    }
}