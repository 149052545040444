.app_bar_holder {
    width: 100%;
}

#desktop_appbar .main_app_bar {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 106px;
    max-width: 1920px;
}

#desktop_appbar .main_app_bar .header_links {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#desktop_appbar .link-header {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 12px;
}

#desktop_appbar .link-header:hover span {
    visibility: visible;
}

#desktop_appbar .link-header span {
    padding: 10px 30px;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

#desktop_appbar .relationship:hover {
    position: relative;
    background-color: #00A14C;

}

#desktop_appbar .relationship .drop-down {
    display: none;
    opacity: 0;
}

#desktop_appbar .relationship:hover .drop-down {
    opacity: 1;
    transition: 0.5s all;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    gap: 10px;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #00A14C;
    border-radius: 4px;
    padding: 5px 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

#desktop_appbar .link-header span::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

#desktop_appbar  .help-icon {
    color: #00A14C;
    font-size: 30px;
    cursor: pointer;
}

#desktop_appbar .app_bar_divider {
    display: block;
    width: 100%;
    background: #FFFDFD;
    border-bottom: 1px solid #00A14C;
}

#desktop_appbar .user_image {
    height: 65px;
    width: 65px;
    border-radius: 50%;
}

#mobile_appbar .main_app_bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#mobile_appbar .menu-icons {
    margin-left: 50px;
    color: #00A14C;
}


#mobile_appbar .menu-mobile {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

#mobile_appbar .header_logo {
    padding: 20px 10px;
    width: 60px;
    height: 60px;
}

#mobile_appbar .header_logo img {
    width: 100%;
    height: 100%;
}

#mobile_appbar .menu-mobile-hide {
    display: none !important;
}

#mobile_appbar .menu-mobile .user-name-section {
    flex: 1;
    background-color: #00A14C;
    z-index: 99;

}

#mobile_appbar .menu-mobile .links-app-bar {
    flex: 3;
    background-color: white;
    height: 70vh;
}

#mobile_appbar .menu-mobile .user-name-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#mobile_appbar .menu-mobile .person-icon {
    font-size: 100px;
    color: white;
}

#mobile_appbar .menu-mobile .close-button {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 30px;
    color: white;
}

#mobile_appbar .menu-mobile .username-drawer {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #FFFFFF;
}

#mobile_appbar .menu-mobile .links-app-bar {
    padding-top: 24px;
    padding-left: 41px;
}

#mobile_appbar .menu-mobile .links-app-bar .inter-14 {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #5A5A5A;
}

#desktop_appbar .main_app_bar .header_logo {
    width: 80px;
    height: 80px;
}

#desktop_appbar .main_app_bar .header_logo img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    #desktop_appbar .main_app_bar {
        width: 100%;
    }

    #desktop_appbar .main_app_bar .header_links {
        margin-right: 30px;
    }

    #desktop_appbar .main_app_bar .header_logo {
        width: 50%;
    }

}

@media only screen and (min-width: 768px) {
    #desktop_appbar {
        display: block;
    }

    #mobile_appbar {
        display: none;
    }

    #mobile_appbar .header_logo {
        padding: 10px 10px;
        width: 50px;
        height: 50px;
    }
}

@media only screen and (max-width: 768px) {
    #desktop_appbar {
        display: none;
    }

    #mobile_appbar {
        position: relative;
        display: flex;
        z-index: 99;

    }

}