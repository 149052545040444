#relationship_box_widget {
    width: 128px;
    height: 177px;
    background: #FFFFFF;
    border: 1px solid #00A14C;
    border-radius: 10px;
}

#relationship_box_widget .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00A14C;
    border-radius: 9px 9px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    height: 44px;
    color: #FFFFFF;
}

#relationship_box_widget .content-box {
    text-align: center;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #5A5A5A;
}