#report-screen .report-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #00A14C;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 14px;
}


@media only screen and (max-width: 768px) {
    #report-screen .report-title {
        justify-content: center;
        background-color: #00A14C;
        color: #fff;
        padding: 23px 0px;
        color: #FFF;
        font-size: 32px;
        font-weight: 600;
    }
}