#identify-principle {
    width: 100%;
    height: 100%;
}

#identify-principle .you-alredy-set-principle {
    margin: 100px auto 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #00A14C;
    width: 70%;
    text-align: center;
}

#identify-principle .button-navigate-to-relation {
    margin: auto;
    padding: 15px 30px;
    border-radius: 10px;
    background-color: #00A14C;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    text-decoration: none;
    cursor: pointer;
}