#flow-screen {
    width: 70%;
    margin: auto;
}

#flow-screen .flow-elements-holder {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

#flow-screen .flow-elements-holder .create-flow-element {
    border-radius: 10px;
    border: 1px solid #00A14C;
    width: 198px;
    height: 254px;
}

#flow-screen .flow-elements-holder .create-flow-element .create-flow-element-top {
    width: 198px;
    height: 46px;
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    color: #F8F8F8;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

#flow-screen .flow-elements-holder .create-flow-element .create-flow-element-bottom {
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#flow-screen .flow-elements-holder .create-flow-element .create-flow-element-bottom .icon {
    font-size: 120px;
    color: #4ECB71;
    cursor: pointer;
}

#flow-screen .flow-message {
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
}

#flow-screen .flow-message div {
    font-size: 25px;
    margin-top: 30px;
    text-align: center;

}

#flow-screen .flow-message div a {
    color: #00A14C;
    text-decoration: none;
    font-weight: bold;

}

@media only screen and (max-width: 768px) {
    #flow-screen {
        width: 100%;

    }

    #flow-screen .flow-message {
        font-size: 18px;
        margin-top: 30px;
        text-align: center;
    }

    #flow-screen .flow-elements-holder {
        margin-top: 30px;
        padding: 0px 20px;
    }

    #flow-screen .flow-elements-holder .create-flow-element {
        display: none;
    }
}
@media only screen and (max-width: 470px) {
    #flow-screen .flow-elements-holder{
        justify-content: center;
    }
}

#delete-item {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.362);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

#delete-item .alert-mesg {
    width: 50%;
    height: 200px;
    background-color: white;
    border-radius: 20px;
}

#delete-item .alert-mesg h1 {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    color: black;
}

#delete-item .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
}

#delete-item .buttons .bt {
    padding: 5px 10px;
    background-color: #00A14C;
    border-radius: 10px;
    cursor: pointer;
    color: white;
}

#delete-item .buttons .close {
    background-color: red;
}

@media only screen and (max-width: 768px) {
    #project .project-list {
        width: 100%;
        margin: 30px auto;
    }

    #delete-item .alert-mesg {
        width: 80%;
    }
}