.main-project-title-holder{
    width: 100%;
}
#desktop {
    width: 70%;
    padding-top: 20px;
    margin: auto;
}

#desktop .project-welcome {
    text-align: center;
    font-weight: 700;
    font-size: min(64px, 4.333vw);
}

#desktop .grid-view-button {
    display: flex;
    align-items: center;
    margin: 40px auto 0px;
}

#desktop .grid-view-text {
    padding-right: 20px;
}

.grid-view-text {
    font-size: 24px;
    font-weight: 700;
}

#desktop .grid-view-text-small {
    font-size: 16px;
    font-weight: 600;
    padding-right: 11px;
}

#mobile {
    padding: 11px 20px;
    align-items: flex-start;
    justify-content: space-between;
}

#mobile .project-welcome {
    font-size: 32px;
    color: white;
    font-weight: 600;
}

#mobile .project-create-now {
    padding-top: 11px;
    padding-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
}

#mobile .grid-view-button {
    display: flex;
    align-items: center;
}

#mobile .grid-view-text {
    padding-right: 11px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
    #desktop {
        display: block;
    }

    #mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #desktop {
        display: none;
    }

    #mobile {
        display: flex;

    }
}