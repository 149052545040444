#principle-alert {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 0px;
    left: 0px;
}

#principle-alert .backgroud-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.397);
}

#principle-alert .box-principle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 30px 20px;
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px 30px;
}

#principle-alert .principle-element {
    text-align: start;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

#principle-alert .principle-element:hover {
    background-color: #D3d3d3;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    #principle-alert .box-principle {
        width: 70vw;

    }
}