#create-verification {
    width: 75%;
    margin: 40px auto;
}

#create-verification .verification-select {
    padding: 10px 20px;
    margin: 33px auto;
}

#create-verification .create-verification-holder {
    max-width: 633px;
    min-width: 320px;
    width: 95%;
    height: 376px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    background: #FFF;
    position: relative;
    margin: auto;
}

#create-verification .create-verification-holder .top-verification-create {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    width: 100%;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-sizing: border-box;
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#create-verification .create-verification-holder .top-verification-create .word-time {
    display: flex;
    align-items: center;
}

#create-verification .create-verification-holder .top-verification-create .time {
    position: absolute;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: 88px;
    left: 25px;
}

#create-verification .create-verification-holder .top-verification-create .entity {
    padding-right: 50px;
}

#create-verification .create-verification-holder .create-verification-content {
    height: 298px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}

#create-verification .create-verification-holder .create-verification-content .word {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 2;
}

#create-verification .create-verification-holder .create-verification-content .type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #5A5A5A;
    position: relative;

}

#create-verification .create-verification-holder .create-verification-content .type h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #5A5A5A;

}

#create-verification .create-verification-holder .create-verification-content .type .line-arrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#create-verification .create-verification-holder .create-verification-content .type .line-arrow .line {
    width: 100%;
    height: 3px;
    background-color: #00A14C;
}

#create-verification .create-verification-holder .create-verification-content .type .line-arrow .arrow {
    font-size: 30px;
    margin-left: -17px;
    color: #00A14C;
}

#create-verification .create-verification-holder .create-verification-content .type .not-related {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: red;
}

#create-verification .create-verification-holder .create-verification-content .entity {
    flex: 2;
    height: 207px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    max-width: 170px;
}

#create-verification .create-verification-holder .create-verification-content .one-entity {
    flex: 2;
    height: 207px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    display: flex;
}

#create-verification .create-verification-holder .create-verification-content .entity img,
#create-verification .create-verification-holder .create-verification-content .entity audio,
#create-verification .create-verification-holder .create-verification-content .entity video,
#create-verification .create-verification-holder .create-verification-content .entity .link-entity-parser,
#create-verification .create-verification-holder .create-verification-content .one-entity img,
#create-verification .create-verification-holder .create-verification-content .one-entity audio,
#create-verification .create-verification-holder .create-verification-content .one-entity video,
#create-verification .create-verification-holder .create-verification-content .one-entity .link-entity-parser {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 9px;
}



#create-verification .buttons-action-verification {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#create-verification .save-button {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A14C;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    margin-top: 40px;
}

.verification-sho-back {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00A14C;
    border-radius: 10px;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    margin: 40px auto 50px;
}

#create-verification .buttons-action-verification .exit {
    margin-right: 10px;
    background-color: red;
}

#create-verification .quetion-verification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.506);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#create-verification .quetion-verification .questions {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 300px;
    border: 1px solid #00A14C;
    border-radius: 30px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#create-verification .quetion-verification .questions .buttonhulder {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
}

#create-verification .quetion-verification .questions .buttonhulder .save {
    padding: 10px 40px;
    background-color: #00A14C;
    border-radius: 4px;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    #create-verification {
        width: 100%;
        margin: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #create-verification .verification-select {
        display: flex;
    }

    #create-verification .create-verification-holder .top-verification-create {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 46px;
    }

    #create-verification .create-verification-holder .top-verification-create .entity {
        padding-right: 20px;
    }

    #create-verification .create-verification-holder .top-verification-create .time {
        color: #F8F8F8;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: unset;
        margin-left: 10px;
    }

    #create-verification .create-verification-holder .create-verification-content .entity {
        height: 128px;
        border-radius: 10px;
        flex: 1;
        max-width: 120px;
    }

    #create-verification .create-verification-holder .create-verification-content .word {
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex: 1.5;
    }

    #create-verification .create-verification-holder .create-verification-content .type {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 2;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-decoration-line: underline;
        color: #5A5A5A;

    }

    #create-verification .buttons-action-verification .exit {
        margin-left: 10px;
    }
}

@media only screen and (min-width: 768px) {
    #create-verification .verification-select {
        display: none;
    }

    #create-verification .buttons-action-verification {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


}

#create-verification select {
    display: none;
}

@media only screen and (max-width: 768px) {
    #create-verification select {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        height: 40px;
    }

    #create-verification .create-verification-holder .create-verification-content .one-entity {
        flex: 2;
        height: 157px;
        border-radius: 10px;
        border: 1px solid #00A14C;
        display: flex;
    }

    #create-verification .quetion-verification .questions {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 300px;
        border: 1px solid #00A14C;
        border-radius: 30px;
        background-color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}