#idnetify-principle-header {
    width: 70%;
    height: 70%;
    display: flex;
    align-items: center;
    margin: 40px auto;
}

#idnetify-principle-header .title {
    color: #00A14C;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#idnetify-principle-header .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 30px;
}

#idnetify-principle-header .buttons .button {
    min-width: 165px;
    height: 51px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#idnetify-principle-header .active {
    color: #FFF;
    background-color: #00A14C;
}

#idnetify-principle-header .not-active {
    color: #00A14C;
    background-color: white;
}

@media only screen and (max-width: 768px) {
    #idnetify-principle-header {
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin: 0px auto 20px;
    }

    #idnetify-principle-header .title {
        background-color: #00A14C;
        width: 100%;
        height: 70px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #idnetify-principle-header .buttons {
        width: 90%;
        gap: 20px;
        margin-top: 10px;
        margin-left: 0px;
    }

    #idnetify-principle-header .buttons .button {
        flex: 1;
        font-size: 13px;
        min-width: 130px;
        height: 41px;
    }
}