#login-box {
    width: 773px;
    height: 709px;
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
    margin: 72px auto;
}

#login-box .login-box-title {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    padding-top: 56px;
    padding-bottom: 69px;
    text-align: center;
}

#login-box .form-login {
    margin: auto;
    width: 297px;
    height: 113px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#login-box .forget-password-link {
    margin: auto;
    width: 297px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

#login-box .login-button {
    margin-top: 46px;
    cursor: pointer;
    margin: 46px auto 0px;
    width: 297px;
    height: 50px;
    background: #00A14C;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#login-box .login-button h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 12px;
}

#login-box .login-button .icon {
    color: #FFFFFF;
}

#login-box .create-account-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);

}

#login-box .create-account-link .link {
    color: #00A14C;
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: 5px;
}

@media only screen and (max-width: 768px) {
    #login-box {
        background: #FFFFFF;
        box-shadow: none;
        margin: 12px auto;
        width: 100%;
    }

    #login-box .login-box-title {
        font-size: 24px;
        padding-top: 26px;
        padding-bottom: 29px;
    }
}