#relationship-view-web .realtionship-title {
    margin-top: 40px;
    color: #00A14C;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#relationship-view-mobile {
    padding: 29px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#relationship-view-mobile .element-holder-mobile-relation {
    border-radius: 10px;
    background: #00A14C;
    height: 38px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0px 30px 0px 10px;
    position: relative;
    cursor: pointer;
}

#relationship-view-mobile .element-holder-mobile-relation .text {
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#relationship-view-mobile .element-holder-mobile-relation .index {
    font-size: 20px;
}

#relationship-view-mobile .element-holder-mobile-relation .arrow {
    font-size: 30px;
    color: #FFF;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
}

#relationship-view-web .notrelated,
#relationship-view-mobile .notrelated {
    position: absolute;
    top: 65px;
    left: 32px;
    font-size: 105px;
    color: red;
}

#relationship-view-web .arrow-holder,
#relationship-view-mobile .arrow-holder {
    position: relative;
}

#relationship-view-mobile .close-relation-view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: red;
    border-radius: 10px;
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: auto;
    cursor: pointer;
}

#show-explanation {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.276);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#show-explanation .content-explanation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #FFF;
    border-radius: 10px;
}

#relationship-view-mobile .close {
    background-color: #00A14C;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: auto;
    color: #FFF;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    #relationship-view-web {
        display: block;
    }

    #relationship-view-mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    #relationship-view-web {
        display: none;
    }

    #relationship-view-mobile {
        display: flex;
    }

    #relationship-view-web .realtionship-title {
        display: none;
    }
}