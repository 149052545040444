#main-linear {
    width: 70%;
    margin: auto;
}

#main-linear .flow-elements-holder {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

#main-linear .flow-elements-holder .create-flow-element {
    border-radius: 10px;
    border: 1px solid #00A14C;
    width: 198px;
    height: 254px;
}

#main-linear .flow-elements-holder .create-flow-element .create-flow-element-top {
    width: 198px;
    height: 46px;
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    color: #F8F8F8;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

#main-linear .flow-elements-holder .create-flow-element .create-flow-element-bottom {
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#main-linear .flow-elements-holder .create-flow-element .create-flow-element-bottom .icon {
    font-size: 120px;
    color: #4ECB71;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    #main-linear {
        width: 100%;

    }

    #main-linear .flow-elements-holder {
        margin-top: 30px;
        padding: 0px 20px;
    }

    #main-linear .flow-elements-holder .create-flow-element {
        display: none;
    }
}

@media only screen and (max-width: 470px) {

    #main-linear .flow-elements-holder {
        margin-top: 30px;
        justify-content: center;
    }


}