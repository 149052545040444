#yes-no-question .answer-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

#yes-no-question .answer-box input {
    accent-color: #00A14C;
    width: 20px;
    height: 20px;
}

#yes-no-question .answer-box h1 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    text-align: start;
}

#yes-no-question .answer-box h1 .green {
    color: #1BC000;
}

#yes-no-question .answer-box h1 .red {
    color: #FF0000;
}