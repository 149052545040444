#create-flow-informations {
    display: flex;
    align-items: center;
    width: 100%;
}

#create-flow-informations .first-box-information {
    width: 400px;
    height: 350px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#create-flow-informations .arrow {
    height: 3px;
    width: 150px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#create-flow-informations p {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    white-space: nowrap;
}

#create-flow-informations .space {
    width: 150px;
}

#create-flow-informations .arrow-right {
    position: absolute;
    right: -10px;
}

#create-flow-informations .x-mark {
    position: absolute;
    right: 20%;
    top: 50%;
    color: red;
    font-size: 50px;
    transform: translate(-50%, -50%);
}

#create-flow-informations .header {
    flex: 1;
    width: 100%;
    background-color: #00A14C;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    font-weight: 600;
    font-size: 20px;
    color: white;

}

#create-flow-informations .change-type {
    margin-right: 15px;
    margin-top: 5px;
    cursor: pointer;
}

#create-flow-informations .bottom {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

#create-flow-informations .sentence-flow {
    flex: 1;
    overflow: hidden;
}

#create-flow-informations .entity-flow {
    flex: 1;
    height: 60%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 5px gray;
}

#questions-flow .title {
    color: #00A14C;
    font-size: 23px;
    text-decoration: underline;
    font-weight: bold;
}


#create-flow-informations .entity-info {
    flex: 1px;
    border-radius: 10px;
    background-color: red;
}

#create-flow-informations .entity-flow #entity-widget {
    width: 200px;
    margin-right: 20px;
}

#create-flow-informations .width-adjust {
    width: 250px;
    height: 350px;
}

#create-flow-informations .third-entity {
    flex: 6;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

#questions-flow {
    margin-top: 50px;
    margin-bottom: 20px;
}

#questions-flow .question {
    margin-top: 20px;
}

#questions-flow input {
    accent-color: #00A14C;
    width: 18px;
    height: 18px;

}

#questions-flow .yes {
    margin-left: 30px;
    padding: 6px;
}

#questions-flow .yes .color {
    padding-right: 3px;
    padding-left: 3px;
}

#questions-flow .yes .red {
    padding-right: 3px;
    padding-left: 3px;
}

#questions-flow .question-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
}

.no-margin {
    margin-top: 20px;
    margin-left: -30px;
}

#infromation-flow-screen .save-button {
    background-color: #00A14C;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-bottom: 50px;
    border-radius: 10px;
    margin-top: 30px;
    color: white;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #create-flow-informations {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        flex-direction: column;
    }

    #create-flow-informations .arrow {
        height: 100px;
        width: 3px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    #create-flow-informations .space {
        height: 100px;
        width: 3px;
    }

    #create-flow-informations p {
        position: absolute;
        z-index: 10;
        left: 20px;
    }

    #create-flow-informations .arrow-right {
        position: absolute;
        bottom: -10px;
        transform: rotateZ(90deg);
    }

    #create-flow-informations .x-mark {
        position: absolute;
        color: red;
        font-size: 50px;
        right: auto;
        top: auto;
        color: red;
        font-size: 50px;
        transform: none;

    }
    .no-answer{
        margin-top: 10px;
    }
    .last-question-no{
        margin-top: 20px;
    }

    #questions-flow .yes {
        padding: 0px;
    }
}

.change-type-main {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.528);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.change-type-main .data-holder {
    padding: 20px 50px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.change-type-main .button {
    width: 100%;
    background-color: #00A14C;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

#create-flow-informations .content-box {
    height: 75%;
    overflow: hidden;
}
.line-last-question{
    width: 100%;
    height: 2px;
    background-color: #00A14C;
    margin-top: 20px;
}