#relationship-screen .relationship-element {
    display: flex;
    align-items: center;
    width: 283.018px;
    height: 400px;
    margin: 50px 0px;
}




#relationship-screen .relationship-element .boxes {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    gap: 36px;

}

#relationship-screen .relationship-element .boxes .relationship-box {
    width: 108px;
    height: 157px;
    border-radius: 10px;
    border: 1px solid #00A14C;
    background: #FFF;
}

#relationship-screen .relationship-element .boxes .relationship-box .title {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    height: 44px;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

#relationship-screen .relationship-element .boxes .relationship-box .content {
    height: 113px;
    color: #5A5A5A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    text-align: start;
    text-overflow: ellipsis;
    border-radius: 0px 0px 9px 9px;
    text-align: center;
}

#relationship-screen .relationship-element .boxes .relationship-box .content .holder {
    height: 133px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

#relationship-screen .relationship-element .boxes .relationship-box .content img,
#relationship-screen .relationship-element .boxes .relationship-box .content video,
#relationship-screen .relationship-element .boxes .relationship-box .content audio {
    width: 100%;
    height: 100%;
}

#relationship-screen .relationship-answer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#relationship-screen .relationship-answer .answer-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

#relationship-screen .relationship-answer .answer-box input {
    accent-color: #00A14C;
    width: 20px;
    height: 20px;
}

#relationship-screen .relationship-answer .answer-box h1 {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
}

#relationship-screen .relationship-answer .answer-box h1 .green {
    color: #1BC000;
}

#relationship-screen .relationship-answer .answer-box h1 .red {
    color: #FF0000;
}



#relationship-screen .relationship-element .third-entity {
    border-radius: 10px;
    border: 1px solid #00A14C;
    background: #FFF;
    min-width: 98px;
}

#relationship-screen .relationship-element .third-entity .title {
    border-radius: 9px 9px 0px 0px;
    background: #00A14C;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #FFF;
    width: 100%;
}

#relationship-screen .relationship-element .third-entity .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    max-height: 60px;
    min-height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    #relationship-screen .relationship-element {
        display: flex;
        align-items: center;
        width: 283.018px;
        height: 400px;
        justify-content: flex-start;
        box-sizing: border-box;
        margin-top: 20px;
    }

    #relationship-screen .relationship-element .third-entity {
        max-width: 80px;
        min-width: 80px;
    }
}